import axios from "axios"
import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      let message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data)

const postProfile = data => post(url.POST_EDIT_PROFILE, data)
const getProfile = loginType => get(getLoggedInUserType() + `${url.GET_PROFILE}`)

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data)

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data)

// get Products
// export const getProducts = () => get(getLoggedInUserType()+url.GET_PRODUCTS)

// get Product detail
// export const getProductDetail = id =>
//   get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
// export const getEvents = () => get(getLoggedInUserType()+url.GET_EVENTS)

// add Events
// export const addNewEvent = event => post(getLoggedInUserType()+url.ADD_NEW_EVENT, event)

// update Event
// export const updateEvent = event => put(url.UPDATE_EVENT, event)

// delete Event
// export const deleteEvent = event =>
//   del(url.DELETE_EVENT, { headers: { event } })

// get Categories
// export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
// export const getChats = () => get(url.GET_CHATS)

// get groups
// export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
// export const getContacts = () => get(url.GET_CONTACTS)

// get messages
// export const getMessages = (roomId = "") =>
//   get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
// export const addMessage = message => post(url.ADD_MESSAGE, message)

// get orders
// export const getOrders = () => get(url.GET_ORDERS)

// add order
// export const addNewOrder = order => post(url.ADD_NEW_ORDER, order)

// update order
// export const updateOrder = order => put(url.UPDATE_ORDER, order)

// delete order
// export const deleteOrder = order =>
//   del(url.DELETE_ORDER, { headers: { order } })

// get cart data
// export const getCartData = () => get(url.GET_CART_DATA)

// get users
export const getCommonUsers = (data) => get(getLoggedInUserType() + url.GET_COMMON_USERS)
export const getCommonUserDetail = (data) => post(getLoggedInUserType() + url.GET_COMMON_USER_DETAIL, data)
export const addCommonUserDetail = (data) => post(getLoggedInUserType() + url.ADD_COMMON_USER, data)
export const addCommonUser = (data) => post(getLoggedInUserType() + url.ADD_COMMON_USER, data)


export const changePassword = (data) => put(getLoggedInUserType() + url.CHANGE_PASSWORD, data)
export const forgotPassword = (data) => post(url.FORGOT_PASSWORD, data)
export const resetPassword = (data) => post(url.RESET_PASSWORD, data)
export const employerResendPassword = (id) => get(getLoggedInUserType() + `${url.EMPLOYER_RESEND_PASSWORD}/${id}`)
export const employeeResendPassword = (id) => get(getLoggedInUserType() + `${url.EMPLOYEE_RESEND_PASSWORD}/${id}`)

export const updateNotificationEmails = (data) => put(getLoggedInUserType() + url.UPDATE_NOTIFICATION_EMAILS, data)
export const updateLcaEmployeeAccess = (data) => put(getLoggedInUserType() + url.UPDATE_LCA_EMPLOYEE_ACCESS, data)
export const updateQuestionaireReview = (data) => put(getLoggedInUserType() + url.UPDATE_QUESTIONAIRE_REVIEW, data)



export const getCommonUserSettingsApiHelper = (data) => post(url.ADD_COMMON_USER, data)

export const uploadUserAttachmentApiHelper = (data) => post(url.ADD_COMMON_USER, data)

// export const getCommonUserSettingsApiHelper = (data) => post(url.ADD_COMMON_USER, data)

// export const getCommonUserSettingsApiHelper = (data) => post(url.ADD_COMMON_USER, data)
// get customers
export const getCustomers = (data) => post(getLoggedInUserType() + url.GET_CUSTOMERS, data)
export const getCustomerDetail = (data) => post(getLoggedInUserType() + url.GET_CUSTOMER_DETAIL, data)
export const addCustomerDetail = (data) => post(getLoggedInUserType() + url.ADD_CUSTOMER, data)
export const addCustomer = (data) => post(getLoggedInUserType() + url.ADD_CUSTOMER, data)
export const updateTemplatePdf = (data) => post(getLoggedInUserType() + url.UPDATE_INVOICE_PDF, data)
export const getTemplateSettingsApiHelper = (data) => post(getLoggedInUserType() + url.GET_CREATE_INVOICE_SETTINGS, data)
export const uploadTemplateAttachmentApiHelper = (data) => post(getLoggedInUserType() + url.UPLOAD_INVOICE_ATTACHMENT_URL, data)
export const updateTemplate = customer => post(getLoggedInUserType() + url.UPDATE_CUSTOMER, customer)
export const deleteTemplate = customer => post(getLoggedInUserType() + url.DELETE_CUSTOMER, customer)


// get templates
export const getTemplates = (data) => post(getLoggedInUserType() + url.GET_TEMPLATES, data)
export const getTemplateDetail = (data) => post(getLoggedInUserType() + url.GET_TEMPLATE_DETAIL, data)
export const addTemplateDetail = (data) => post(getLoggedInUserType() + url.ADD_TEMPLATE, data)
export const addTemplate = (data) => post(getLoggedInUserType() + url.ADD_TEMPLATE, data)
export const updatePafTemplate = (data) => put(getLoggedInUserType() + url.UPDATE_PAF_TEMPLATE, data)


export const sendCustomer = (data) => post(getLoggedInUserType() + url.SEND_INVOICE, data)
export const updateCustomerPdf = (data) => post(getLoggedInUserType() + url.UPDATE_INVOICE_PDF, data)
export const getCustomerSettingsApiHelper = (data) => post(getLoggedInUserType() + url.GET_CREATE_INVOICE_SETTINGS, data)
export const uploadCustomerAttachmentApiHelper = (data) => post(getLoggedInUserType() + url.UPLOAD_INVOICE_ATTACHMENT_URL, data)


// add CUSTOMER
export const addNewCustomer = customer => post(getLoggedInUserType() + url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = customer => post(getLoggedInUserType() + url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = customer => post(getLoggedInUserType() + url.DELETE_CUSTOMER, customer)

// get shops
// export const getShops = () => get(url.GET_SHOPS)

// get wallet
// export const getWallet = () => get(url.GET_WALLET)

// get crypto order
// export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = (data) => post(getLoggedInUserType() + url.GET_INVOICES, data)

// get invoices
export const getInvoiceDetail = (data) => post(getLoggedInUserType() + url.GET_INVOICE_DETAIL, data)



// // get invoice details
// export const getInvoiceDetail = id =>
//   get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

//add invoice details
export const addInvoiceDetail = (data) => post(getLoggedInUserType() + url.ADD_INVOICE, data)

export const addInvoice = (data) => post(getLoggedInUserType() + url.ADD_INVOICE, data)
export const sendInvoice = (data) => post(getLoggedInUserType() + url.SEND_INVOICE, data)
export const updateInvoicePdf = (data) => post(getLoggedInUserType() + url.UPDATE_INVOICE_PDF, data)
export const recordPayment = (data) => post(getLoggedInUserType() + url.RECORD_PAYMENT, data)
export const getInvoiceTimesheetDetails = (data) => post(getLoggedInUserType() + url.GET_INVOICE_TIMESHEET_DETAIL_URL, data)

export const getCreateInvoiceSettingsApiHelper = (data) => post(getLoggedInUserType() + url.GET_CREATE_INVOICE_SETTINGS, data)
export const getInvoiceSettingsApiHelper = () => get(getLoggedInUserType() + url.GET_INVOICE_SETTINGS)
export const getQuickbookCallbackApiHelper = (data) => post(getLoggedInUserType() + url.GET_QUICKBOOK_CALLBACK, data)
export const getQuickbookRefreshApiHelper = () => get(getLoggedInUserType() + url.GET_QUICKBOOK_REFRESH)
export const getQuickbookStatusApiHelper = () => get(getLoggedInUserType() + url.GET_QUICKBOOK_STATUS)
export const getQuickbookSyncInvoiceApiHelper = (data) => post(getLoggedInUserType() + url.GET_QUICKBOOK_SYNC_INVOICE, data)
export const updateInvoiceSettingsApiHelper = (data) => post(getLoggedInUserType() + url.UPDATE_INVOICE_SETTINGS, data)
export const connectQuickBookApi = (data) => post(getLoggedInUserType() + url.CONNECT_QUICKBOOKS_API_URL, data)
export const uploadInvoiceAttachmentApiHelper = (data) => post(getLoggedInUserType() + url.UPLOAD_INVOICE_ATTACHMENT_URL, data)

// Add Timesheet
export const AddTimesheetApiHelper = (data) => post(getLoggedInUserType() + url.ADD_TIMESHEET, data)
export const validateTimesheetByPeriodApiHelper = (data) => post(getLoggedInUserType() + url.VALIDATE_TIMESHEET_BY_PERIOD_URL, data)
export const FetchEmployeeTimesheetsApiHelper = (data) => post(getLoggedInUserType() + url.GET_EMPLOYEETIMESHEETS, data)
export const FetchProjectApiHelper = (data) => get(getLoggedInUserType() + url.GET_TIMESHEET_PROJECTS, data)
export const AddProjectApiHelper = (data) => post(getLoggedInUserType() + url.CREATE_PROJECT, data)
export const uploadEmployerEsignApi = (data) => post(getLoggedInUserType() + url.UPLOAD_EMPLOYER_ESIGN, data)
export const getEmployerEsignApi = (data) => get(getLoggedInUserType() + url.GET_EMPLOYER_ESIGN)

// Employer Timesheet
export const getEmployerTimesheetApiHelper = (data) => post(getLoggedInUserType() + url.GET_EMPLOYERTIMESHEETS, data)
export const getEmployerTimesheetDetailApiHelper = timesheet_id =>
  post(getLoggedInUserType() + url.GET_EMPLOYERTIMESHEETDETAIL, timesheet_id)
export const updateEmployerViewTimesheet = (data) => post(getLoggedInUserType() + url.UPDATE_EMPLOYERVIEWTIMESHEET, data)

//delete timesheet 
export const deleteTimesheetApiHelper = (data) => del(`${getLoggedInUserType() + url.DELETE_TIMESHEET}/${data}`)


//delete invoice 
export const deleteInvoice = (data) => del(getLoggedInUserType() + url.DELETE_INVOICE, { headers: { data } })

//update invoice
export const updateInvoice = (data) => post(getLoggedInUserType() + url.UPDATE_INVOICE, data)

// get project
export const getProjects = () => get(getLoggedInUserType() + url.GET_PROJECTS)

// get project details
export const getProjectsDetails = id =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(getLoggedInUserType() + url.GET_TASKS)

// get contacts
export const getUsers = () => get(getLoggedInUserType() + url.GET_USERS)

// add user
export const addNewUser = user => post(getLoggedInUserType() + url.ADD_NEW_USER, user)

// update user
export const updateUser = user => put(getLoggedInUserType() + url.UPDATE_USER, user)

// delete user
export const deleteUser = user => del(getLoggedInUserType() + url.DELETE_USER, { headers: { user } })

/** PROJECT */
// add user
export const addNewProject = project => post(getLoggedInUserType() + url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = project => put(getLoggedInUserType() + url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = project =>
  del(getLoggedInUserType() + url.DELETE_PROJECT, { headers: { project } })

export const getUserProfile = () => get(getLoggedInUserType() + url.GET_USER_PROFILE)

// get inboxmail
export const getInboxMails = () => get(getLoggedInUserType() + url.GET_INBOX_MAILS)

// add inboxmail
export const addNewInboxMail = inboxmail =>
  post(getLoggedInUserType() + url.ADD_NEW_INBOX_MAIL, inboxmail)

// delete inboxmail
export const deleteInboxMail = inboxmail =>
  del(getLoggedInUserType() + url.DELETE_INBOX_MAIL, { headers: { inboxmail } })

// get starredmail
export const getStarredMails = () => get(getLoggedInUserType() + url.GET_STARRED_MAILS)

// get importantmail
export const getImportantMails = () => get(getLoggedInUserType() + url.GET_IMPORTANT_MAILS)

// get sent mail
export const getSentMails = () => get(getLoggedInUserType() + url.GET_SENT_MAILS)

// get trash mail
export const getTrashMails = () => get(getLoggedInUserType() + url.GET_TRASH_MAILS)

// get starredmail
export const getDraftMails = () => get(getLoggedInUserType() + url.GET_DRAFT_MAILS)

// get dashboard charts data
export const getWeeklyData = () => get(getLoggedInUserType() + url.GET_WEEKLY_DATA)
export const getYearlyData = () => get(getLoggedInUserType() + url.GET_YEARLY_DATA)
export const getMonthlyData = () => get(getLoggedInUserType() + url.GET_MONTHLY_DATA)

export const topSellingData = month =>
  get(`${url.TOP_SELLING_DATA}/${month}`, { params: { month } })

export const getEarningChartsData = month =>
  get(`${url.GET_EARNING_DATA}/${month}`, { params: { month } })

const getProductComents = () => get(getLoggedInUserType() + url.GET_PRODUCT_COMMENTS)

const onLikeComment = (commentId, productId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}`, {
    params: { commentId, productId },
  })
}
const onLikeReply = (commentId, productId, replyId) => {
  return post(`${url.ON_LIKNE_COMMENT}/${productId}/${commentId}/${replyId}`, {
    params: { commentId, productId, replyId },
  })
}

const onAddReply = (commentId, productId, replyText) => {
  return post(`${url.ON_ADD_REPLY}/${productId}/${commentId}`, {
    params: { commentId, productId, replyText },
  })
}

const onAddComment = (productId, commentText) => {
  return post(`${url.ON_ADD_COMMENT}/${productId}`, {
    params: { productId, commentText },
  })
}

// get dashboard Market overview data

export const getMarketoverViewData = (periodType) =>
  get(`${url.GET_MARKET_OVERVIEW}/${periodType}`, { params: { periodType } });

export const getWallentData = (data) =>
  get(`${url.GET_WALLENT_BALANCE}/${data}`, { params: { data } });

export const getInvestedData = (data) =>
  get(`${url.GET_Invested_Overview}/${data}`, { params: { data } });


//iMMIDOC API ROUTES  

// get Qnnrtypes
export const getQnnrtypes = (id) => get(`${getLoggedInUserType() + url.GET_QNNRTYPES}${id ? `/${id}` : ''}`)

// get Qnnrtype details
export const getQnnrtypeDetail = id =>
  get(`${getLoggedInUserType() + url.GET_QNNRTYPE_DETAIL}/${id}`)

//add Qnnrtype details
export const addQnnrtype = (data) =>
  post(getLoggedInUserType() + url.ADD_QNNRTYPE, data)

//delete Casetype 
export const deleteQnnrtype = (data) => del(`${getLoggedInUserType() + url.DELETE_QNNRTYPE}/${data}`)

//update Casetype
export const updateQnnrtype = (data) => put(`${getLoggedInUserType() + url.DELETE_QNNRTYPE}/${data.id}`, data)


// get Casetypes
export const getCasetypes = () => get(getLoggedInUserType() + url.GET_CASETYPES)

// get employers
export const getEmployersAPIHelper = (data) => post(getLoggedInUserType() + url.GET_EMPLOYERS, data)

export const getEmployerDetailsAPIHelper = id =>
  get(`${getLoggedInUserType() + url.GET_EMPLOYER_DETAIL}/${id}`)

export const updateEmployerDetailsAPIHelper = (data) => post(`${getLoggedInUserType() + url.UPDATE_EMPLOYER_DETAILS}`, data)


// get Casetype details
export const getCasetypeDetail = id =>
  get(`${getLoggedInUserType() + url.GET_CASETYPE_DETAIL}/${id}`)

//add Casetype details
export const addCasetype = (data) =>
  post(getLoggedInUserType() + url.ADD_CASETYPE, data)

//delete Casetype 
export const deleteCasetype = (data) => del(`${getLoggedInUserType() + url.DELETE_CASETYPE}/${data}`)

//update Casetype
export const updateCasetype = (data) => put(`${getLoggedInUserType() + url.UPDATE_CASETYPE}/${data.id}`, data)

const getLoggedInUserType = () => {
  var userType = window.localStorage.getItem("user_type")
  return userType;
}

// get Attributes
export const getAttributes = () => {
  return get(getLoggedInUserType() + url.GET_ATTRIBUTES);
}

// get Attribute details
export const getAttributeDetail = id =>
  get(`${getLoggedInUserType() + url.GET_ATTRIBUTE_DETAIL}/${id}`, { params: { id } })

//add Attribute details
export const addAttribute = (data) =>
  post(getLoggedInUserType() + url.ADD_ATTRIBUTE, data)

//delete Attribute 
export const deleteAttribute = (data) => del(`${getLoggedInUserType() + url.DELETE_ATTRIBUTE}/${data}`, { headers: { data } })

//update Attribute
export const updateAttribute = (data) => put(`${getLoggedInUserType() + url.UPDATE_ATTRIBUTE}/${data.id}`, data)



// get Attributesets
export const getAttributesets = () => get(getLoggedInUserType() + url.GET_ATTRIBUTESETS)

// get Attributeset details
export const getAttributesetDetail = id =>
  get(`${getLoggedInUserType() + url.GET_ATTRIBUTESET_DETAIL}/${id}`)

//add Attributeset details
export const addAttributeset = (data) =>
  post(getLoggedInUserType() + url.ADD_ATTRIBUTESET, data)

//delete Attributeset 
export const deleteAttributeset = (data) => del(`${getLoggedInUserType() + url.DELETE_ATTRIBUTESET}/${data}`, { headers: { data } })

//update Attributeset
export const updateAttributeset = (data) => put(`${getLoggedInUserType() + url.UPDATE_ATTRIBUTESET}/${data.id}`, data)


// get Attributegroup
export const getAttributegroups = () => get(getLoggedInUserType() + url.GET_ATTRIBUTEGROUPS)

// get Attributegroup details
export const getAttributegroupDetail = id =>
  get(`${getLoggedInUserType() + url.GET_ATTRIBUTEGROUP_DETAIL}/${id}`, { params: { id } })

//add Attributegroup details
export const addAttributegroup = (data) =>
  post(getLoggedInUserType() + url.ADD_ATTRIBUTEGROUP, data)

//delete Attributegroup 
export const deleteAttributegroup = (data) => del(`${getLoggedInUserType() + url.DELETE_ATTRIBUTEGROUP}/${data}`, { headers: { data } })

//update Attributegroup
export const updateAttributegroup = (data) => put(`${getLoggedInUserType() + url.UPDATE_ATTRIBUTEGROUP}/${data.id}`, data)


// get Attributetemplate
export const getAttributetemplates = () => get(getLoggedInUserType() + url.GET_ATTRIBUTETEMPLATES)

// get Attributetemplate details
export const getAttributetemplateDetail = id => get(`${getLoggedInUserType() + url.GET_ATTRIBUTETEMPLATE_DETAIL}/${id}`, { params: { id } })

//add Attributetemplate details
export const addAttributetemplate = (data) => post(getLoggedInUserType() + url.ADD_ATTRIBUTETEMPLATE, data)

//delete Attributetemplate 
export const deleteAttributetemplate = (data) => del(`${getLoggedInUserType() + url.DELETE_ATTRIBUTETEMPLATE}/${data}`, { headers: { data } })

//update Attributetemplate
export const updateAttributetemplate = (data) => put(`${getLoggedInUserType() + url.UPDATE_ATTRIBUTETEMPLATE}/${data.id}`, data)
//Attorneys
export const getAttorneys = (data) => post(getLoggedInUserType() + url.GET_ATTORNEYS, data)

export const getAttorneyDetailApi = id => get(`${getLoggedInUserType() + url.GET_ATTORNEYS_DETAIL}/${id}`)

export const addAttorney = (data) => post(getLoggedInUserType() + url.ADD_ATTORNEYS, data)
export const deleteAttorney = (data) => del(`${getLoggedInUserType() + url.DELETE_ATTORNEYS}/${data}`)
export const updateAttorney = (data) => post(`${getLoggedInUserType() + url.UPDATE_ATTORNEYS}`, data)
export const updateAttorneyUnlockQunnr = (data) => post(`${getLoggedInUserType() + url.GET_ATTORNEY_UNLOCK_QNNR}`, data)
export const updateAttorneyCaseLCA = (data) => post(`${getLoggedInUserType() + url.UPDATE_ATTORNEY_CASELCA}`, data)

//ATTORNEY ROUTES
//COMPANY INFO 
export const getAttorneyCompany = () => get(getLoggedInUserType() + url.GET_ATTORNEY_COMPANY_INFO_LIST)
export const getAttorneyCompanyDetail = id => get(`${getLoggedInUserType() + url.GET_ATTORNEY_COMPANY_INFO}`)
export const addAttorneyCompany = (data) => post(getLoggedInUserType() + url.ADD_ATTORNEY_COMPANY_INFO, data)
export const deleteAttorneyCompany = (data) => del(`${getLoggedInUserType() + url.DELETE_QNNRTYPE}`)
export const updateAttorneyCompany = (data) => post(`${getLoggedInUserType() + url.UPDATE_ATTORNEY_COMPANY_INFO}`, data)
export const getAttorneyElgibileStatus = () => get(getLoggedInUserType() + url.GET_ATTORNEY_ELIGIBILITY_STATUS)
//contacts
export const getAttorneyContacts = (data) => post(getLoggedInUserType() + url.GET_ATTORNEY_CONTACTS, data)
export const getAttorneyContactDetail = (data) => post(`${getLoggedInUserType() + url.GET_ATTORNEY_CONTACT}`, data)
export const addAttorneyContact = (data) => post(getLoggedInUserType() + url.ADD_ATTORNEY_CONTACT, data)
export const deleteAttorneyContact = (data) => del(`${getLoggedInUserType() + url.DELETE_ATTORNEY_CONTACT}/${data}`)
export const updateAttorneyContact = (data) => post(`${getLoggedInUserType() + url.UPDATE_ATTORNEY_CONTACT}/${data.employer_id}`, data)
export const validateCompanyEmail = (data) => post(getLoggedInUserType() + url.VALIDATE_COMPANY_EMAILID, data)

//contacts
export const getAttorneyDashboard = () => get(getLoggedInUserType() + url.GET_ATTORNEY_DASHBOARD)


//Employer Routes

//contacts
export const getEmployerDashboard = () => get(getLoggedInUserType() + url.GET_EMPLOYER_DASHBOARD)

//COMPANY INFO 
export const getEmployerCompany = () => get(getLoggedInUserType() + url.GET_EMPLOYER_COMPANY_INFO_LIST)
export const getEmployerCompanyDetail = id => get(`${getLoggedInUserType() + url.GET_EMPLOYER_COMPANY_INFO}`)
export const addEmployerCompany = (data) => post(getLoggedInUserType() + url.ADD_EMPLOYER_COMPANY_INFO, data)
export const deleteEmployerCompany = (data) => del(`${getLoggedInUserType() + url.DELETE_EMPLOYER_COMPANY_INFO}`)
export const updateEmployerCompany = (data) => post(`${getLoggedInUserType() + url.UPDATE_EMPLOYER_COMPANY_INFO}`, data)
//contacts
export const getEmployerContacts = (data) => post(getLoggedInUserType() + url.GET_EMPLOYER_CONTACTS, data)
//export const getEmployerContactDetail = id => get(`${url.GET_EMPLOYER_CONTACT}/${id}`)
export const getEmployerContactDetail = (data) => post(getLoggedInUserType() + url.GET_EMPLOYER_CONTACT, data)
export const addEmployerContact = (data) => post(getLoggedInUserType() + url.ADD_EMPLOYER_CONTACT, data)
export const deleteEmployerContact = (data) => post(getLoggedInUserType() + url.DELETE_EMPLOYER_CONTACT, data)
export const updateEmployerContact = (data) => put(`${getLoggedInUserType() + url.UPDATE_EMPLOYER_CASE}/${data.id}`, data)
export const updateEmployeeJobDetails = (data) => post(getLoggedInUserType() + url.UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL, data)
export const updateEmployeePayDetails = (data) => post(getLoggedInUserType() + url.UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL, data)
export const updateEmployeePersonalDetails = (data) => post(getLoggedInUserType() + url.UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL, data)
export const getEmployerEmployeeCountriesList = () => get(getLoggedInUserType() + url.GET_EMPLOYEREMPLOYEE_COUNTRIESLIST)
export const getEmployerEmployeeStatesList = (data) => post(getLoggedInUserType() + url.GET_EMPLOYEREMPLOYEE_STATESLIST, data)
export const addEmployerEmployeeDocHelper = (data) => post(getLoggedInUserType() + url.ADD_EMPLOYEREMPLOYEE_DOCUMENT, data)
export const shareEmployerEmployeeDocHelper = (data) => post(getLoggedInUserType() + url.SHARE_EMPLOYEREMPLOYEE_DOCUMENT, data)
export const assignDocumentToCaseHelper = (data) => post(getLoggedInUserType() + url.ASSIGN_DOCUMENT_TO_CASE, data)
export const documentsByCaseHelper = (data) => post(getLoggedInUserType() + url.GET_DOCUMENTS_BY_CASE, data)
export const validateEmployeeEmail = (data) => post(getLoggedInUserType() + url.VALIDATE_EMPLOYEE_EMAILID, data)



//contacts

export const bundleCaseforms = (data) => post(getLoggedInUserType() + url.BUNDLE_CASEFORMS, data)
export const bundleCasedocs = (data) => post(getLoggedInUserType() + url.BUNDLE_CASE_DOCUMENTS, data)
export const saveCaseforms = (data) => post(getLoggedInUserType() + url.SAVE_CASEFORMS, data)
export const getEmployerCases = (data) => post(getLoggedInUserType() + url.GET_EMPLOYER_CASES, data)
export const getEmployerPaf = (data) => post(getLoggedInUserType() + url.GET_EMPLOYER_PAF, data)
export const getEmployerPafForm = (data) => post(getLoggedInUserType() + url.GET_EMPLOYER_PAF_FORM, data)
export const getEmployerPafForms = (data) => post(getLoggedInUserType() + url.GET_EMPLOYER_PAF_FORMS, data)
export const uploadEmployerLca = (data) => post(getLoggedInUserType() + url.UPLOAD_EMPLOYER_LCA, data)
export const getPafTemplateVariables = (data) => get(getLoggedInUserType() + url.GET_PAF_TEMPLATE_VARIABLES, data)
export const getEmployerPafInfo = (data) => post(getLoggedInUserType() + url.GET_EMPLOYER_PAFINFO, data)
export const createPafBundle = (data) => post(getLoggedInUserType() + url.CREATE_PAF_BUNDLE, data)
export const sendPafEmployeeNotification = (data) => post(getLoggedInUserType() + url.SEND_PAF_EMPLOYEE_NOTIFICATION, data)
export const updateEmployerPafInfo = (data) => put(getLoggedInUserType() + url.UPDATE_EMPLOYER_PAFINFO, data)
export const updateEmployerPafForm = (data) => put(getLoggedInUserType() + url.UPDATE_EMPLOYER_PAF_FORM, data)
export const getEmployerCaseDetail = id => get(`${getLoggedInUserType() + url.GET_EMPLOYER_CASE}/${id}`)
export const addEmployerCase = (data) => post(getLoggedInUserType() + url.ADD_EMPLOYER_CASE, data)
export const deleteEmployerCase = (data) => del(`${getLoggedInUserType() + url.DELETE_EMPLOYER_CASE}/${data}`)
export const updateEmployerCase = (data) => put(`${getLoggedInUserType() + url.UPDATE_EMPLOYER_CASE}/${data.id}`, data)
export const getEmployerCaseTypes = () => get(getLoggedInUserType() + url.GET_EMPLOYER_CASETYPES)
export const getEmployerQnnrtypes = (id) => get(`${getLoggedInUserType() + url.GET_EMPLOYER_QNNRTYPES}/${id}`)

export const getEmployerPayrollsApi = (data) => post(getLoggedInUserType() + url.GET_EMPLOYER_PAYROLLS, data)
export const getEmployerPayrollApi = (data) => post(`${getLoggedInUserType() + url.GET_EMPLOYER_VIEW_PAYROLL}`, data)
export const addEmployerPayrollApi = (data) => post(getLoggedInUserType() + url.ADD_EMPLOYER_CREATE_PAYROLL, data)
export const updateEmployerPayrollApi = (data) => post(`${getLoggedInUserType() + url.UPDATE_EMPLOYER_UPDATE_PAYROLL}`, data)
export const refreshEmployeePayrollApi = (data) => post(`${getLoggedInUserType() + url.REFRESH_EMPLOYEE_UPDATE_PAYROLL}`, data)
export const refreshEmployerPayrollApi = (data) => post(`${getLoggedInUserType() + url.REFRESH_EMPLOYER_UPDATE_PAYROLL}`, data)
export const deleteEmployerPayroll = (data) => del(`${getLoggedInUserType() + url.DELETE_EMPLOYER_UPDATE_PAYROLL}/${data}`)


export const saveNote = (data) => post(getLoggedInUserType() + url.SAVE_NOTE, data)
export const saveReminder = (data) => post(getLoggedInUserType() + url.SAVE_REMINDER, data)
export const saveMessage = (data) => post(getLoggedInUserType() + url.SAVE_MESSAGE, data)

export const getNote = (data) => post(getLoggedInUserType() + url.GET_NOTE, data)
export const getReminder = (data) => post(getLoggedInUserType() + url.GET_REMINDER, data)
export const getMessage = (data) => post(getLoggedInUserType() + url.GET_MESSAGE, data)




//attorney cases
export const getAttorneyCases = (data) => post(getLoggedInUserType() + url.GET_CASES, data)
export const sortAttorneyCaseForms = (data) => put(getLoggedInUserType() + url.SORT_CASEFORMS, data)

export const getAttorneyCasesOverview = () => get(getLoggedInUserType() + url.GET_CASES_OVERVIEW)

export const getAttorneyCaseDetail = (data) => post(getLoggedInUserType() + url.GET_CASE_DETAIL, data)
export const getCaseQnnrDetails = (data) => post(getLoggedInUserType() + url.GET_CASE_QNNR_DETAILS, data)
export const getCaseForms = (data) => post(getLoggedInUserType() + url.GET_CASE_FORMS, data)
export const getCaseDocuments = (data) => post(getLoggedInUserType() + url.GET_CASE_DOCUMENTS, data)
export const getCaseTimelines = (data) => post(getLoggedInUserType() + url.GET_CASE_TIMELINES, data)
export const addAttorneyCase = (data) => post(getLoggedInUserType() + url.ADD_ATTORNEY_CASE, data)
export const deleteAttorneyCase = (data) => post(getLoggedInUserType() + url.DELETE_ATTORNEY_CASE, data)
export const updateAttorneyCase = (data) => put(getLoggedInUserType() + url.UPDATE_ATTORNEY_CASE, data)
export const getAttorneyCaseTypes = () => get(getLoggedInUserType() + url.GET_ATTORNEY_CASETYPES)
export const getAttorneyQnnrtypes = (id) => get(`${getLoggedInUserType() + url.GET_ATTORNEY_QNNRTYPES}/${id}`)
export const generateForm = (data) => post(getLoggedInUserType() + url.POST_GENERATE_FORMS, data)
export const generateFormDetail = (data) => post(getLoggedInUserType() + url.POST_GENERATE_FORMS, data)
export const saveCaseFormTemplateData = (data) => post(getLoggedInUserType() + url.SAVE_CASE_FORM_TEMPLATE_DATA, data)
export const deleteCaseFormApi = (data) => post(getLoggedInUserType() + url.DELETE_CASE_FORM_URL, data)
export const getEmployerEmployeesApi = (data) => post(getLoggedInUserType() + url.GET_EMPLOYER_EMPLOYEES, data)

export const updateAttorneyCaseForms = (data) => post(`${getLoggedInUserType() + url.UDATE_ATTORNEY_CASE_FORM_SHARE}`, data)
export const revokeAttorneyCaseFormsReview = (data) => post(
  data.formType === 'forms' ? `${getLoggedInUserType() + url.REVOKE_ATTORNEY_CASE_FORM_SHARE_REVIEW}` : `${getLoggedInUserType() + url.REVOKE_ATTORNEY_CASE_DOCUMENT_REVIEW}`
  , data)
export const addAttorneyDocumentUploadForm = (data) => post(`${getLoggedInUserType() + url.ADD_ATTORNEY_DOCUMENT_UPLOAD_FORM}`, data)
export const requestAttorneyDocumentForm = (data) => post(`${getLoggedInUserType() + url.REQUEST_ATTORNEY_DOCUMENT_FORM}`, data)
export const shareUploadedDocument = (data) => post(getLoggedInUserType() + url.SHARE_UPLOADED_DOCUMENT, data)
export const sendReminderDocument = (data) => post(`${getLoggedInUserType() + url.SEND_DOCUMENT_REMINDER}`, data)
export const getCaseDependents = (data) => post(`${getLoggedInUserType() + url.GET_CASE_DEPENDENTS}`, data)


export const getAttorneyDocuments = (data) => get(`${getLoggedInUserType() + url.GET_ATTORNEY_MASTER_DOCUMENTS}`)
export const updateAttorneyDocuments = (data) => put(`${getLoggedInUserType() + url.UPDATE_ATTORNEY_MASTER_DOCUMENTS}`, data)
export const deleteAttorneyDocuments = (data) => del(`${getLoggedInUserType() + url.DELETE_ATTORNEY_MASTER_DOCUMENTS}/${data.doc_ids}`, data)




//Employee Routes
export const getEmployeeDashboardNotifications = () => get(getLoggedInUserType() + url.GET_EMPLOYEE_NOTIFICATIONS)
export const readNotificationApi = (data) => post(getLoggedInUserType() + url.READ_NOTIFICATION_URL, data)
export const getEmployeePafEsignDetails = (data) => post(getLoggedInUserType() + url.GET_EMPLOYEE_PAF_ESIGN_DETAILS, data)
export const updateEmployeePafEsignForm = (data) => put(getLoggedInUserType() + url.UPDATE_EMPLOYEE_PAF_ESIGN_FORM, data)

export const getEmployeeDashboard = () => get(getLoggedInUserType() + url.GET_EMPLOYEE_DASHBOARD)
export const getEmployeeCases = (data) => get(getLoggedInUserType() + url.GET_EMPLOYEE_CASES)
export const getEmployeeCaseDetail = id => get(`${getLoggedInUserType() + url.GET_EMPLOYEE_CASE}/${id}`)
export const addEmployeeCase = (data) => post(getLoggedInUserType() + url.ADD_EMPLOYEE_CASE, data)
export const deleteEmployeeCase = (data) => del(`${getLoggedInUserType() + url.DELETE_EMPLOYEE_CASE}/${data}`)
export const updateEmployeeCase = (data) => put(`${getLoggedInUserType() + url.UPDATE_EMPLOYEE_CASE}/${data.id}`, data)
export const getEmployeeCaseQnnrTemplateInfo = (data) => post(data.user_type + url.GET_EMPLOYEE_CASE_QNNR_TEMPLATE, data)
export const addEmployeeCaseQnnrTemplateInfo = (data) => post(getLoggedInUserType() + url.ADD_EMPLOYEE_CASE_QNNR_TEMPLATE, data)
export const uploadQnnrDocument = (data) => post(getLoggedInUserType() + url.UPLOAD_QNNR_DOCUMENT, data)
export const deleteQnnrDocument = (data) => post(getLoggedInUserType() + url.DELETE_QNNR_DOCUMENT, data)

// get Case form Attributesets
export const getCaseFormAttributesets = (data) => post(getLoggedInUserType() + url.GET_CASEFORMATTRIBUTESETS, data)
export const addCaseformAttributeset = (data) => post(getLoggedInUserType() + url.ADD_CASEFORMATTRIBUTESET, data)
export const updateCaseformAttributeset = (data) => put(`${getLoggedInUserType() + url.UPDATE_CASEFORMATTRIBUTESET}/${data.id}`, data)
export const deleteCaseformAttributeset = (data) => del(`${getLoggedInUserType() + url.DELETE_CASEFORMATTRIBUTESET}/${data}`)
export const updateCaseformAttributeGroups = (data) => put(`${getLoggedInUserType() + url.UPDATE_CASEFORMATTRIBUTEGROUP}/${data.id}`, data)
export const deleteCaseformAttributeGroups = (data) => del(`${getLoggedInUserType() + url.DELETE_CASEFORMATTRIBUTEGROUP}/${data}`)
export const updateCaseformAttributeTemplates = (data) => put(`${getLoggedInUserType() + url.UPDATE_CASEFORMATTRIBUTE_TEMPLATE_DETAIL}/${data.id}`, data)
export const deleteCaseformAttributeTemplates = (data) => del(`${getLoggedInUserType() + url.DELETE_CASEFORMATTRIBUTEGROUP}/${data}`)

export const getCaseFormAttributeTemplateDetailInfo = id => get(`${getLoggedInUserType() + url.GET_CASEFORMATTRIBUTE_TEMPLATE_DETAIL}/${id}`)
export const getCaseFormAttributeGroupDetailInfo = id => get(`${getLoggedInUserType() + url.GET_CASEFORMATTRIBUTE_GROUP_DETAIL}/${id}`)
export const getCaseFormAttributeSetDetailInfo = id => get(`${getLoggedInUserType() + url.GET_CASEFORMATTRIBUTESETDETAIL}/${id}`)
export const getCaseFormAttributeDetailInfo = id => get(`${getLoggedInUserType() + url.GET_CASEFORMATTRIBUTE_DETAIL}/${id}`)

//export const getcaseformAttributes = () => get(getLoggedInUserType()+url.GET_CASEFORMATTRIBUTES)

export const getcaseformAttributes = (data) => post(getLoggedInUserType() + url.GET_CASEFORMATTRIBUTES, data)
//add Casetype details
export const addCaseFormAttributes = (data) => post(getLoggedInUserType() + url.ADD_CASEFORMATTRIBUTE, data)
export const updateCaseFormAttributes = (data) => put(`${getLoggedInUserType() + url.UPDATE_CASEFORMATTRIBUTE}/${data.id}`, data)

export const deleteCaseformAttribute = (data) => del(`${getLoggedInUserType() + url.DELETE_CASEFORMATTRIBUTE}/${data}`)

// get Caseforms
export const getCaseFormsList = () => get(getLoggedInUserType() + url.GET_CASEFORMSLIST)
export const getCaseForm = id => get(`${getLoggedInUserType() + url.GET_CASE_FORM}/${id}`, { params: { id } })

export const addCaseforms = (data) => post(getLoggedInUserType() + url.ADD_CASEFORMDATA, data)
export const updateCaseForm = (data) => put(getLoggedInUserType() + url.UPDATE_CASE_FORM, data)


//export const getCaseformsattributegroups = () => post(getLoggedInUserType()+url.GET_CASEFORMATTRIBUTEGROUPS)
export const getCaseformattributetemp = () => post(getLoggedInUserType() + url.GET_CASEFORMATTRIBUTETEMPS)
export const addCaseformattributetemplate = (data) => post(getLoggedInUserType() + url.ADD_CASEFORMATTRIBUTETEMPS, data)
export const getCaseformattributegroups = (data) => post(getLoggedInUserType() + url.GET_CASEFORMATTRIBUTEGROUPS, data)
export const addCaseformattributegroup = (data) => post(getLoggedInUserType() + url.ADD_CASEFORMATTRIBUTEGROUP, data)

export const getEmployeeStatesList = (data) => post(`${getLoggedInUserType() + url.GET_EMPLOYEE_CASE_STATES}`, data)
export const getEmployeeCountrysList = (data) => get(getLoggedInUserType() + url.GET_EMPLOYEE_CASE_COUNTRYS)


export {
  getLoggedInUser, getProductComents, getProfile, isUserAuthenticated, onAddComment, onAddReply, onLikeComment,
  onLikeReply, postFakeForgetPwd, postFakeLogin, postFakeRegister, postJwtForgetPwd, postJwtLogin, postJwtProfile, postJwtRegister, postProfile
}

