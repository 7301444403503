//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//QNNRTYPES
export const GET_QNNRTYPES = "/qnnr-types"
export const GET_QNNRTYPE_DETAIL = "/qnnr-type"
export const ADD_QNNRTYPE = "/qnnr-type"
export const DELETE_QNNRTYPE = "/qnnr-type"
export const UPDATE_QNNRTYPE = "/qnnr-type"


//Attorney
export const GET_ATTORNEYS = "/attorneys"
export const GET_ATTORNEYS_DETAIL = "/attorney"
export const ADD_ATTORNEYS = "/attorney-onboarding"
export const DELETE_ATTORNEYS = "/delete-attorney"
export const UPDATE_ATTORNEYS = "/update-attorney"


//Casetypes


export const GET_CASETYPES = "/case-types"
export const GET_CASETYPE_DETAIL = "/case-type"
export const ADD_CASETYPE = "/case-type"
export const DELETE_CASETYPE = "/case-type"
export const UPDATE_CASETYPE = "/case-type"

// EMPLOYERS
export const GET_EMPLOYERS = "/employers"
export const GET_EMPLOYER_DETAIL = "/employer"
export const UPDATE_EMPLOYER_DETAILS = "/update-employer"


//Attributes
export const GET_ATTRIBUTES = "/qnnr-attributes"
export const GET_ATTRIBUTE_DETAIL = "/qnnr-attribute"
export const ADD_ATTRIBUTE = "/qnnr-attribute"
export const DELETE_ATTRIBUTE = "/qnnr-attribute"
export const UPDATE_ATTRIBUTE = "/qnnr-attribute"

//admin/qnnr-attribute-sets
export const GET_ATTRIBUTESETS = "/qnnr-attribute-sets"
export const GET_ATTRIBUTESET_DETAIL = "/qnnr-attribute-set"
export const ADD_ATTRIBUTESET = "/qnnr-attribute-set"
export const DELETE_ATTRIBUTESET = "/qnnr-attribute-set"
export const UPDATE_ATTRIBUTESET = "/qnnr-attribute-set"

//admin/qnnr-attribute-sets
//admin/qnnr-attribute-groupss
export const GET_ATTRIBUTEGROUPS = "/qnnr-attribute-groups"
export const GET_ATTRIBUTEGROUP_DETAIL = "/qnnr-attribute-group"
export const ADD_ATTRIBUTEGROUP = "/qnnr-attribute-group"
export const DELETE_ATTRIBUTEGROUP = "/qnnr-attribute-group"
export const UPDATE_ATTRIBUTEGROUP = "/qnnr-attribute-group"
//admin/qnnr-attribute-sets
//admin/qnnr-attribute-templatess
export const GET_ATTRIBUTETEMPLATES = "/qnnr-attribute-templates"
export const GET_ATTRIBUTETEMPLATE_DETAIL = "/qnnr-attribute-template"
export const ADD_ATTRIBUTETEMPLATE = "/qnnr-attribute-template"
export const DELETE_ATTRIBUTETEMPLATE = "/qnnr-attribute-template"
export const UPDATE_ATTRIBUTETEMPLATE = "/qnnr-attribute-template"




//Attorney Routes:
//COMPANYINFO ROUTES
export const GET_ATTORNEY_COMPANY_INFO_LIST = "/get-company-info"
export const GET_ATTORNEY_COMPANY_INFO = "/get-company-info"
export const ADD_ATTORNEY_COMPANY_INFO = "/update-company-info"
export const DELETE_ATTORNEY_COMPANY_INFO = "/update-company-info"
export const UPDATE_ATTORNEY_COMPANY_INFO = "/update-company-info"
export const GET_ATTORNEY_ELIGIBILITY_STATUS = "/get-elgibility-statuses"
//CONTACTS ROUTES
export const GET_ATTORNEY_CONTACTS = "/get-contacts"
export const GET_ATTORNEY_CONTACT = "/view-contact"
export const ADD_ATTORNEY_CONTACT = "/create-contact"
export const DELETE_ATTORNEY_CONTACT = "/delete-contact"
export const UPDATE_ATTORNEY_CONTACT = "/update-contact"
export const VALIDATE_COMPANY_EMAILID = "/validate-employer-email"

//DASHBOARD ROUTES
export const GET_ATTORNEY_DASHBOARD = "/dashboard"
export const GET_ATTORNEY_UNLOCK_QNNR = "/unlock-qnnr"

//EMPLOYER Routes:
//COMPANY ROUTES
export const GET_EMPLOYER_COMPANY_INFO_LIST = "/get-company-info"
export const GET_EMPLOYER_COMPANY_INFO = "/get-company-info"
export const ADD_EMPLOYER_COMPANY_INFO = "/update-company-info"
export const DELETE_EMPLOYER_COMPANY_INFO = "/update-company-info"
export const UPDATE_EMPLOYER_COMPANY_INFO = "/update-company-info"
//contacts ROUTES
export const GET_EMPLOYER_CONTACTS = "/get-employees"
export const GET_EMPLOYER_CONTACT = "/get-employee-details"
export const ADD_EMPLOYER_CONTACT = "/create-employee"
export const DELETE_EMPLOYER_CONTACT = "/change-employee-status"
export const UPDATE_EMPLOYER_CONTACT = "/get-employee"
export const UPDATE_EMPLOYEREMPLOYEE_JOBDETAIL = "/update-employee-job-details"
export const UPDATE_EMPLOYEREMPLOYEE_PAYDETAIL = "/update-employee-pay-details"
export const UPDATE_EMPLOYEREMPLOYEE_PERSONALDETAIL = "/update-employee-personal-details"
export const GET_EMPLOYEREMPLOYEE_COUNTRIESLIST = "/get-all-countries"
export const GET_EMPLOYEREMPLOYEE_STATESLIST = "/get-all-states"
export const ADD_EMPLOYEREMPLOYEE_DOCUMENT = "/add-employee-document"
export const SHARE_EMPLOYEREMPLOYEE_DOCUMENT = "/share-employee-document"
export const ASSIGN_DOCUMENT_TO_CASE = "/assign-employee-document-to-case"
export const GET_DOCUMENTS_BY_CASE = "/get-case-documents"
export const VALIDATE_EMPLOYEE_EMAILID = "/validate-employee-email"




//CASES ROUTES
export const GET_EMPLOYER_CASES = "/get-cases"
export const GET_EMPLOYER_CASE = "/get-case"
export const GET_EMPLOYER_PAF = "/get-paf-cases"
export const GET_EMPLOYER_PAF_FORM = "/get-case-paf-form"
export const GET_EMPLOYER_PAF_FORMS = "/get-case-paf-forms"
export const CREATE_PAF_BUNDLE = "/create-bundle"
export const SEND_PAF_EMPLOYEE_NOTIFICATION = "/send-paf-employee-notification"
export const GET_EMPLOYER_PAFINFO = "/get-paf-application-info"
export const UPDATE_EMPLOYER_PAFINFO = "/update-paf-application-info"
export const UPDATE_EMPLOYER_PAF_FORM = "/update-case-paf-form"
export const ADD_EMPLOYER_CASE = "/create-case"
export const DELETE_EMPLOYER_CASE = "/get-case"
export const UPDATE_EMPLOYER_CASE = "/get-case"
export const GET_EMPLOYER_CASETYPES = "/case-types"
export const GET_EMPLOYER_QNNRTYPES = "/qnnr-types"
export const UPLOAD_EMPLOYER_LCA = "/upload-lca"
export const GET_PAF_TEMPLATE_VARIABLES = "/get-paf-template-variables"


export const GET_NOTE = "/get-case-notes"
export const GET_REMINDER = "/get-case-reminders"
export const GET_MESSAGE = "/get-case-messages"

export const SAVE_NOTE = "/save-note"
export const SAVE_REMINDER = "/save-reminder"
export const SAVE_MESSAGE = "/save-message"

export const BUNDLE_CASEFORMS = "/bundle-caseforms"
export const BUNDLE_CASE_DOCUMENTS = "/bundle-casedocuments"
export const GET_CASES = "/get-cases"
export const GET_CASES_OVERVIEW = "/get-cases-overview"
export const SORT_CASEFORMS = "/sort-case-forms"
export const SAVE_CASEFORMS = "/save-case-form"

export const GET_CASE_DETAIL = "/get-case-details"
export const GET_CASE_QNNR_DETAILS = "/get-qnnr-details"
export const GET_CASE_FORMS = "/get-case-forms"
export const GET_CASE_DOCUMENTS = "/get-case-documents"
export const GET_CASE_TIMELINES = "/get-case-timelines"
export const ADD_ATTORNEY_CASE = "/create-case"
export const DELETE_ATTORNEY_CASE = "/change-case-status"
export const UPDATE_ATTORNEY_CASE = "/update-case-details"
export const UPDATE_ATTORNEY_CASELCA = "/update-case-lca-details"
export const GET_ATTORNEY_CASETYPES = "/case-types"
export const GET_ATTORNEY_QNNRTYPES = "/qnnr-types"
export const GET_ATTORNEY_CASE = "/get-case"
export const UDATE_ATTORNEY_CASE_FORM_SHARE = "/share-caseform"
export const REVOKE_ATTORNEY_CASE_FORM_SHARE_REVIEW = "/revoke-shared-caseform"
export const REVOKE_ATTORNEY_CASE_DOCUMENT_REVIEW = "/revoke-shared-casedoc"
export const ADD_ATTORNEY_DOCUMENT_UPLOAD_FORM = "/share-add-doc"
export const REQUEST_ATTORNEY_DOCUMENT_FORM = "/request-casedoc"
export const DELETE_CASE_FORM_URL = "/delete-case-form"
export const GET_EMPLOYER_EMPLOYEES = "/get-employer-employees"


export const GET_ATTORNEY_MASTER_DOCUMENTS = "/get-documents"
export const UPDATE_ATTORNEY_MASTER_DOCUMENTS = "/update-documents"
export const DELETE_ATTORNEY_MASTER_DOCUMENTS = "/delete-document"

export const SHARE_UPLOADED_DOCUMENT = "/share-uploaded-doc"
export const SEND_DOCUMENT_REMINDER = "/send-document-reminder"
export const GET_CASE_DEPENDENTS = "/get-case-dependents"


export const POST_GENERATE_FORMS = "/generate-case-template"
export const SAVE_CASE_FORM_TEMPLATE_DATA = "/save-case-form-template-data"


//DASHBOARD ROUTES
export const GET_EMPLOYER_DASHBOARD = "/dashboard"


//Employee Routes
//DASHBOARD ROUTES
export const GET_EMPLOYEE_DASHBOARD = "/dashboard"
export const GET_EMPLOYEE_CASES = "/get-cases"
export const GET_EMPLOYEE_CASE = "/get-case"
export const ADD_EMPLOYEE_CASE = "/create-case"
export const DELETE_EMPLOYEE_CASE = "/get-case"
export const UPDATE_EMPLOYEE_CASE = "/get-case"
export const GET_EMPLOYEE_CASE_QNNR_TEMPLATE = "/get-case-qnnr-template"
export const ADD_EMPLOYEE_CASE_QNNR_TEMPLATE = "/save-case-qnnr"
export const UPLOAD_QNNR_DOCUMENT = "/upload-qnnr-document"
export const DELETE_QNNR_DOCUMENT = "/delete-case-document"
export const GET_EMPLOYEE_NOTIFICATIONS = "/get-notifications"
export const READ_NOTIFICATION_URL = "/read-notification"
export const GET_EMPLOYEE_PAF_ESIGN_DETAILS = "/get-paf-esign-form"
export const UPDATE_EMPLOYEE_PAF_ESIGN_FORM = "/update-paf-esign-form"

export const GET_EMPLOYEE_CASE_STATES = "/get-all-states"
export const GET_EMPLOYEE_CASE_COUNTRYS = "/get-all-countries"


//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/get-profile-info"
export const GET_PROFILE = "/get-profile-info"

//PRODUCTS

export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CHATS = "/chats"
export const GET_GROUPS = "/groups"
export const GET_CONTACTS = "/contacts"
export const GET_MESSAGES = "/messages"
export const ADD_MESSAGE = "/add/messages"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
// export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/get-invoices"
export const GET_INVOICE_DETAIL = "/get-invoice"
export const ADD_INVOICE = "/create-invoice"
export const SEND_INVOICE = "/send-invoice"
export const UPDATE_INVOICE_PDF = "/update-invoice-pdf"
export const RECORD_PAYMENT = "/invoice-record-payment"
export const GET_INVOICE_TIMESHEET_DETAIL_URL = "/get-invoice-timesheet-details"
export const GET_CREATE_INVOICE_SETTINGS = "/get-create-invoice-settings"
export const GET_INVOICE_SETTINGS = "/get-invoice-settings"
export const GET_QUICKBOOK_CALLBACK = "/quickbook-callback"
export const GET_QUICKBOOK_SYNC_INVOICE = "/quickbook-sync-invoice"
export const GET_QUICKBOOK_STATUS = "/quickbook-status"
export const GET_QUICKBOOK_REFRESH = "/quickbook-refresh"
export const UPDATE_INVOICE_SETTINGS = "/update-invoice-settings"
export const CONNECT_QUICKBOOKS_API_URL = "/connect-quickbook"
export const UPLOAD_INVOICE_ATTACHMENT_URL = "/upload-invoice-attachement"
export const DELETE_INVOICE = "/delete/invoice"
export const UPDATE_INVOICE = "/update-invoice"

//PROFILE
export const CHANGE_PASSWORD = "/change-password"
export const FORGOT_PASSWORD = "/forgot-password"
export const RESET_PASSWORD = "/reset-password"
export const EMPLOYER_RESEND_PASSWORD = "/employer-resend-password"
export const EMPLOYEE_RESEND_PASSWORD = "/employee-resend-password"
export const UPDATE_QUESTIONAIRE_REVIEW = "/update-questionnaire-review"
export const UPDATE_LCA_EMPLOYEE_ACCESS = "/update-lca-employee-access"
export const UPDATE_NOTIFICATION_EMAILS = "/update-notification-emails"

//USERS
export const GET_COMMON_USERS = "/get-all-users"
export const GET_COMMON_USER_DETAIL = "/get-user"
export const ADD_COMMON_USER = "/create-user"

//TEMPLATES
export const GET_TEMPLATES = "/get-paf-templates"
export const GET_TEMPLATE_DETAIL = "/get-paf-template"
export const ADD_TEMPLATE = "/create-template"
export const UPDATE_TEMPLATE_PDF = "/update-template-pdf"
export const UPDATE_PAF_TEMPLATE = "/update-paf-template"

//CUSTOMERS
export const GET_CUSTOMERS = "/get-customers"
export const GET_CUSTOMER_DETAIL = "/get-customer"
export const ADD_CUSTOMER = "/create-customer"
export const SEND_CUSTOMER = "/send-customer"
export const UPDATE_CUSTOMER_PDF = "/update-customer-pdf"
export const GET_CREATE_CUSTOMER_SETTINGS = "/get-create-customer-settings"
export const UPLOAD_CUSTOMER_ATTACHMENT_URL = "/upload-customer-attachement"
export const DELETE_CUSTOMER = "/delete-customer"
export const UPDATE_CUSTOMER = "/update-customer"

//EMPLOYEE TIMESHEETS
export const ADD_TIMESHEET = "/create-timesheet"
export const VALIDATE_TIMESHEET_BY_PERIOD_URL = "/validate-timesheet-by-period"
export const GET_EMPLOYEETIMESHEETS = "/get-timesheets"
export const GET_TIMESHEET_PROJECTS = "/get-projects"
export const CREATE_PROJECT = "/create-project"


export const DELETE_TIMESHEET = "/delete-timesheet"


// EMPLOYER TIMESHEETS
export const GET_EMPLOYERTIMESHEETS = "/get-timesheets"
export const GET_EMPLOYERTIMESHEETDETAIL = "/view-timesheet"
export const UPDATE_EMPLOYERVIEWTIMESHEET = "/change-timesheet-status"

// EMPLOYER PAYROLL
export const GET_EMPLOYER_PAYROLLS = "/get-payrolls"
export const GET_EMPLOYER_VIEW_PAYROLL = "/view-payroll"
export const ADD_EMPLOYER_CREATE_PAYROLL = "/create-payroll"
export const UPDATE_EMPLOYER_UPDATE_PAYROLL = "/update-payroll"
export const DELETE_EMPLOYER_UPDATE_PAYROLL = "/delete-payroll"
export const REFRESH_EMPLOYER_UPDATE_PAYROLL = "/refresh-payroll"
export const REFRESH_EMPLOYEE_UPDATE_PAYROLL = "/refresh-employee-payroll"
export const UPLOAD_EMPLOYER_ESIGN = "/upload-esign"
export const GET_EMPLOYER_ESIGN = "/get-profile-info"


//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = "/users"
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const UPDATE_USER = "/update/user"
export const DELETE_USER = "/delete/user"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"

export const GET_MARKET_OVERVIEW = "GET_MARKET_OVERVIEW"
export const GET_MARKET_OVERVIEW_SUCCESS = "GET_MARKET_OVERVIEW_SUCCESS"
export const GET_MARKET_OVERVIEW_FAIL = "GET_MARKET_OVERVIEW_FAIL"

export const GET_WALLENT_BALANCE = "GET_WALLENT_BALANCE"

export const GET_WALLENT_BALANCE_ALL_DATA = "GET_MARKET_ALL_DATA"

export const GET_Invested_Overview = "GET_Invested_Overview"

//admin/caseform-attribute-sets
export const GET_CASEFORMATTRIBUTESETS = "/case-form-attribute-sets"
export const GET_CASEFORMATTRIBUTES = "/case-form-attributes"
export const GET_CASEFORMSLIST = "/get-all-case-forms"
export const GET_CASEFORMATTRIBUTEGROUPS = "/case-form-attribute-groups"
export const ADD_CASEFORMATTRIBUTEGROUP = "/case-form-attribute-group"
export const GET_CASEFORMATTRIBUTETEMPS = "/case-form-attribute-templates"
export const ADD_CASEFORMATTRIBUTETEMPS = "/case-form-attribute-template"
export const ADD_CASEFORMATTRIBUTE = "/case-form-attribute"
export const UPDATE_CASEFORMATTRIBUTE = "/case-form-attribute"
export const DELETE_CASEFORMATTRIBUTE = "/case-form-attribute"

export const ADD_CASEFORMATTRIBUTESET = "/case-form-attribute-set"
export const UPDATE_CASEFORMATTRIBUTESET = "/case-form-attribute-set"
export const DELETE_CASEFORMATTRIBUTESET = "/case-form-attribute-set"
export const ADD_CASEFORMDATA = "/create-case-form"
export const UPDATE_CASE_FORM = "/update-case-form"
export const GET_CASE_FORM = "/get-case-form"

export const GET_CASEFORMATTRIBUTE_TEMPLATE_DETAIL = "/case-form-attribute-template"
export const UPDATE_CASEFORMATTRIBUTE_TEMPLATE_DETAIL = "/case-form-attribute-template"
export const GET_CASEFORMATTRIBUTE_GROUP_DETAIL = "/case-form-attribute-group"
export const UPDATE_CASEFORMATTRIBUTEGROUP = "/case-form-attribute-group"
export const DELETE_CASEFORMATTRIBUTEGROUP = "/case-form-attribute-group"
export const GET_CASEFORMATTRIBUTESETDETAIL = "/case-form-attribute-set"
export const GET_CASEFORMATTRIBUTE_DETAIL = "/case-form-attribute"

